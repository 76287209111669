import { utilsFontSizeClass } from '@/utils/computed-classes'

export default {
  props: {
    fontSize: {
      type: String,
      default: 'xl',
      required: false
    },
    lgFontSize: {
      type: String,
      default: '2xl',
      required: false
    },
    text: {
      type: Object,
      default: () => ({})
    },
    textColor: {
      type: String,
      default: 'black'
    },
    textColorHex: {
      type: String,
      default: null
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    contentAlign: {
      type: String,
      default: 'middle'
    },
    ctas: {
      type: Array,
      default: () => []
    },
    headlines: {
      type: Array,
      default: () => []
    },
    mobileIndent: {
      type: String,
      default: ''
    }
  },
  computed: {
    fontSizeClass () {
      return utilsFontSizeClass(this.fontSize, this.lgFontSize)
    },
    textColorStyle () {
      if (!this.textColorHex) {
        return {}
      }

      return { color: this.textColorHex }
    },
    textAlignClass () {
      return {
        left: 'text-left',
        right: 'text-right',
        center: 'text-center',
        justify: 'text-justify'
      }[this.textAlign]
    },
    contentAlignClass () {
      return {
        top: 'h-full justify-start',
        middle: 'h-full justify-center',
        bottom: 'h-full justify-end'
      }[this.contentAlign]
    },
    mobileIndentClass () {
      return {
        left: 'ml-16',
        right: 'mr-16'
      }[this.mobileIndent]
    },
    formattedText () {
      // return this.text && this.text.html ? this.text.html : ''
      return this.text && this.text.html ? this.text.html.replace(/(?:\r\n|\r|\n)/g, '<br />') : ''
    }
  }
}
